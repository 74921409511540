<template>
    <NavBar />
    <div class="achievements-page">
      <div class="certifications-container">
        <div class="card" v-for="cert in certifications" :key="cert.id">
          <img :src="cert.logo" :alt="cert.name + ' logo'" class="cert-logo">
          <div class="cert-title">{{ cert.name }}</div>
          <!-- Button with the click event attached to it -->
          <button class="view-button" @click="openCertification(cert.link)">
            View <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
</template>
  



<script>
export default {
  name: 'AchievementsPage',
  data() {
    return {
      certifications: [
        { id: 1, name: 'Microsoft Certified : Azure Fundamentals ',  logo: '/microsoft.png', link: '/Microsoft_Azure_Fundamentals.pdf' },
        { id: 2, name: 'Stanford Online : Machine Learning', logo: '/stanford.png', link: '/Stanford_ML.pdf' },
        { id: 3, name: 'CETI : Tech and Leadership', logo: '/ceti.png', link: '/CETI_Tech_Leadership.pdf' },
        { id: 4, name: 'Virtusa : NeuralHack', logo: '/NeuralHack.png', link: '/NeuralHack.pdf' }
      ]
    };
  },
  methods: {
    openCertification(url) {
      window.open(url, '_blank');
    }
  }
}
</script>


<style scoped>
.achievements-page {
  text-align: center;
}

.certifications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3em;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 200px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.cert-logo {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.cert-title {
  height: 60px; /* Adjust based on your needs */
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  text-align: center;
  padding: 0 10px; /* Provides padding and prevents overflow */
  overflow: hidden; /* Hides any overflowing text */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  
}

.view-button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 0 0 10px 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-button:hover {
  background-color: #0056b3;
}

.fas {  
  margin-left: 5px;
}
</style>
