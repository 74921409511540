<template>
  <div class="blog-page">
    <NavBar />
    <div class = "title">
      <h3>Stay tuned!</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPage'
}
</script>

<style scoped>
.blog-page {
  text-align: center;
}

.title{
  margin-top: 1.2em;
}
</style>
