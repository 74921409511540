<template>
  <div class="portfolio-page">
    <NavBar />
    <div class = "portfolio-container">
        <div class = "project-card" v-for="project in projects" :key="project.id" @click="openProject(project.link)">
          <div class = "project-image" @click="openProject(project.link)">
            <img :src="project.image" :alt="project.title + ' image'">
          </div>
          <div class="project-title">
            <span @click="openProject(project.link)">{{ project.title }}</span>
          </div>
          <div class="project-tech-stack">
            <div class="tech-items">
              <span v-for="tech in project.techStack" :key="tech" class="tech-item">{{ tech }}</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'PortfolioPage',
    data() {
      return {
        projects: [
            {
              id: 1,
              title: 'QuickConnect', 
              image: '/quickconnect.png', 
              link: 'https://github.com/waquas9248/QuickConnect',
              techStack: ['Python', 'Django ORM', 'Vue.js', 'MySQL']
            },
            {
              id: 2,
              title: 'Movie Theater Ticketing App',
              image: '/movietheater.png',
              link: 'https://github.com/waquas9248/MovieTheaterSystem',
              techStack: ['C#', '.NET', 'MySQL']
            },
            {
              id: 3,
              title: 'Student Hub',
              image: '/studhub.png',
              link: 'https://github.com/waquas9248/StudentHub',
              techStack: ['Java', 'Spring', 'React', 'PostgreSQL', 'Azure']
            },
            {
              id: 4,
              title: 'Image Classifier',
              image: '/bdo.png',
              link: 'https://github.com/waquas9248/Big-Data-Organization',
              techStack: ['Python', 'Spark', 'Kubernetes', 'scikit-learn']
            },
            {
              id: 5,
              title: 'Outlier Detector - Financial Transactions',
              image: '/dm.png',
              link: 'https://github.com/waquas9248/SE512',
              techStack: ['Python', 'scikit-learn', 'Pickle', 'Jupyter']
            }
        ]
      };
    },
    methods: {
      openProject(url) {
        window.open(url, '_blank');
      }
    }
  }
</script>

<style scoped>

  .portfolio-page {
    text-align: center;
  }

  .portfolio-intro{
    font-size: 1.5em;
    font-family: 'Playfair Display', serif;
    margin-top: 0.5em;
  }

  .portfolio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2em;
  }

  .project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 200px;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    cursor: pointer;

  }

  .project-image img {
    width: 100%;
    height: auto;
    border-radius: 4px; 
    object-fit: cover;
  }

  .project-image{
    margin-bottom: 1em;
    margin-top: 10px;
  }

  .project-image :hover{
    cursor: pointer;
  }

  .project-title {
    /* height: 60px; Adjust based on your needs */
    font-size: 1.2em;
    display: inline;
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
    text-align: center;
    /* margin-top: 10px; */
    padding: 0 10px; /* Provides padding and prevents overflow */
    overflow: hidden; /* Hides any overflowing text */
    margin-bottom: 10px;
  }

  .project-card:hover .project-title {
    color: #007BFF;
    transition: color 0.2s ease; /* Smooth background transition */
  }

  .project-tech-stack {
  margin-top: 8px;
  font-size: 14px;
  }

  .tech-items {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 8px; /* Add space between tech items */
    margin: 5px;
    padding-bottom: 5px;
  }

  .tech-item {
    background-color: #f3f4f6; /* Light background for contrast */
    color: #333; /* Dark text color */
    border-radius: 4px; /* Rounded corners for modern look */
    padding: 4px 10px; /* Padding for spacing inside the tag */
    font-size: 14px;
    line-height: 1.5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: default; /* No pointer cursor, as it's not clickable */
  }

  /* .project-title:hover {
    color: #007BFF;
    cursor: pointer;
   
  } */

  /* .view-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 0 0 10px 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .view-button:hover {
    background-color: #0056b3;
  }

  .fas {  
    margin-left: 5px;
  } */
</style>
